/** Material Baseline */
$mainColor: #FFFFFF;

/** Fonts */
@font-face {
  font-family: spyitalic;
  src: url(../fonts/spy-italic.ttf);
}

@font-face {
  font-family: spyitalic-semi;
  src: url(../fonts/spy-semi-italic.ttf);
}

@font-face {
  font-family: sarica;
  src: url(../fonts/sarica.ttf);
}

@font-face {
  font-family: chollasansbold;
  src: url(../fonts/chollasansbold.woff);
}

@font-face {
  font-family: chollasansreg;
  src: url(../fonts/chollasansreg.woff);
}

@font-face {
  font-family: spy-regular;
  src: url(../fonts/spy-regular.ttf);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html, body, div#AppRoot, .frame-root, .frame-content {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  @media only screen and (min-width: '1280px') {
    overflow: hidden;
  }
}

h1 {
  color: $mainColor;
  font-size: 1.4em;
  font-weight: 500;
}

h2 {
  font-size: 2em;
  margin-top: 0em;
}

body, .frame-root, .frame-content {
  font-family: 'Roboto', 'Lato', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../default/images/sci-fi_background@3x-min.png'), url('../default/images/dot-globe-map@3x-min.png');
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  background-color: #000000 !important;
  color: #000;

  a {
    color: #607d8c;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }

  @media only screen and (orientation: landscape) {
    #orientation {
      display: none;
    }
  }

  @media only screen and (orientation: portrait) {
    background-image: url('../default/rotate/bg-home-black.jpg');
    #AppRoot {
      display: none;
    }
    #orientation {
      display: block;
    }
  }
  @media only screen and (max-width: 500px) {
    background-image: url('../default/rotate/bg-home-black.jpg');
    #AppRoot {
      display: none;
    }
    #orientation {
      display: block;
    }
  }
}

.fullscreen-enabled {
  background-image: url('../default/images/sci-fi_background@3x-min.png'), url('../default/images/dot-globe-map@3x-min.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #000000;
}

.main {
  padding: 0 1em;
}

.poster {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.page-container {
  width: 100%;
  text-align: left;
  margin-top: -4.2em;
}

button.custom-btn {
  display: block;
  border: solid 1px $mainColor;
  width: 100%;
  color: $mainColor;
  background-color: #eee;
}

a.custom-link {
  margin: 2em 0;
  display: block;
  text-decoration: underline;
  font-weight: 500;
}

.side-menu {
  a {
    color: white;
  }
}
